let DOMAIN = '';
let SSO_LOGIN_URL = '';

if (process.env.REACT_APP_ENV === 'production') {
  // production
  DOMAIN = window.location.origin;
  SSO_LOGIN_URL = 'TODO';
} else {
  // local | stage
  DOMAIN = 'https://bo-stage.pascalgaming.com';
  const LOCAL_DOMAIN = window.location.origin;
  const SSO_STATE = process.env.REACT_APP_ENV === 'development' ? DOMAIN : LOCAL_DOMAIN;
  SSO_LOGIN_URL = `https://stage-api.accounts-bc.com/connect/authorize?client_id=PascalGaming&response_type=code+token+id_token&scope=openid+profile+email+offline_access+introspect.full.access&redirect_uri=https://bo-stage.pascalgaming.com/api/auth/callback&state=${SSO_STATE}/&nonce=nonce&response_mode=form_post`;
}

const API_CORE = `${DOMAIN}/api`;

export { API_CORE, SSO_LOGIN_URL }; 
